import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


import InfoHeader from "/content/assets/Keep-Info-Header.jpg"
import OutsideHeader from "/content/assets/Outside-Resources-Header.jpg"

import PeopleRacing from "/content/assets/people-racing.jpg"
import PeopleSport from "/content/assets/people-non-racing.jpg"
import InfoCard from "/content/assets/KEEP-info-card.jpg"

import { Helmet } from "react-helmet"

const Resources = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Resources</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="resources-hero">
            <div class="hero-body">
            </div>
      </section>      
      <main>
      <h1>Resources</h1>
      <div class="keep-resources">
        <div class="resource-header">
          <img src={InfoHeader} alt="Career Pathways" />
          <span class="resource-title">KEEP Foundation Educational Resources</span>
        </div>
      </div>
      <br /><br />
      <h3>Educational Presentations</h3>
      <ul>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/1orhv_gwz08iSTzxYz5_qDFHpS9L-_ZB8/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">Basics of Hoof Care</a></li>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/1hZdYHyWSUGZvJVqa8TU5XHVpU704R3qV/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">Economics of Keeping a Horse</a></li>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/1B5_L4IYndFFwpboW-F4A3nuB12eG3i9b/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">Equine Emergencies and First Aid</a></li>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/1relloHWLC9caxNCxb5eNn7IGCccKRVbB/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">History of the Horse</a></li>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/17OYS59FmZ1mBZe6w2f07_LiAIzb071n8/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">Horse and Handler Safety</a></li>
        <li><a rel="noreferrer" href="https://docs.google.com/presentation/d/1relloHWLC9caxNCxb5eNn7IGCccKRVbB/edit?usp=sharing&ouid=100384907122772749402&rtpof=true&sd=true" target="_blank">Western Tack and Equipment</a></li>
      </ul>
      <br /><br />
      <h3>Educational Graphics</h3>
      <div class="resource-thumbs">
        <div class="resource-thumb"><a rel="noreferrer" href="https://drive.google.com/file/d/1-QiczC7AEyAQm-lCeyWT40N5SLpn1OhY/view?usp=sharing" target="_blank"><img src={PeopleRacing} alt="Racing" /></a></div>
        <div class="resource-thumb"><a rel="noreferrer" href="https://drive.google.com/file/d/1ovCsR2LMhwLRiOa9-LaJxFJ4bpWewNOE/view?usp=sharing" target="_blank"><img src={PeopleSport} alt="Sport" /></a></div>
        <div class="resource-thumb"><a rel="noreferrer" href="https://drive.google.com/file/d/18XulcvuvtGOUSmgez0adYITPwYBVCdpM/view?usp=sharing" target="_blank"><img src={InfoCard} alt="Info" /></a></div>
      </div>
      <br /><br />
      <h3>Equine Career Pathway Documents</h3>
      <div class="resource-links">
        
        <a rel="noreferrer" href="https://drive.google.com/file/d/1KPDyO7IquNsIOv1pYoI-iSn4TT5ItaAH/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-tbracing">
          <span class="link-item-text">Thoroughbred Racing</span>
        </div>
        </a>

        <a rel="noreferrer" href="https://drive.google.com/file/d/1MTkfagq95rCNV9EtOMaQlEmhhCZ_j4Na/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-healthcare">
          <span class="link-item-text">Equine Healthcare</span>
        </div>
        </a>

        <a rel="noreferrer" href="https://drive.google.com/file/d/1MiGYvtCtfsC0Zvo6HA4WhoWRDTx4qTte/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-transportation">
          <span class="link-item-text">Equine Transportation</span>
        </div>
        </a>

        <a rel="noreferrer" href="https://drive.google.com/file/d/1jV8uzvAlmLT3aHNHOAdu_VH5pEp5xp6E/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-horses">
          <span class="link-item-text">Administration</span>
        </div>
        </a>

        <a rel="noreferrer" href="https://drive.google.com/file/d/16HW3u5uz--0NCeyCPwQeK1evWqsHMDd7/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-nonracing">
          <span class="link-item-text">Non-Pathway Jobs</span>
        </div>
        </a>

        <a rel="noreferrer" href="https://drive.google.com/file/d/1FsisGhSWcQ3YwM8mlXdqKylImxms1m4k/view?usp=sharing" target="_blank">
        <div class="resource-links-item link-item-tbpathway">
          <span class="link-item-text">TB Care and Sales</span>
        </div>
        </a>
      </div>

      <div class="keep-resources">
        <div class="resource-header">
          <img src={OutsideHeader} alt="Outside Resources" />
          <span class="resource-title">Outside Resources</span>
        </div>
      </div>

      <strong>Post-Secondary Equine Education</strong>
      <ul>
        <li><a rel="noreferrer" href="https://equine.ca.uky.edu/" target="_blank">University of Kentucky</a></li>
        <li><a rel="noreferrer" href="https://business.louisville.edu/academics-programs/equine/" target="_blank">University of Louisville</a></li>
        <li><a rel="noreferrer" href="https://www.georgetowncollege.edu/academics/programs-of-distinction/equine-scholars-program" target="_blank">Georgetown College Equine Scholars Program</a></li>
        <li><a rel="noreferrer" href="https://www.midway.edu/equine/" target="_blank">Midway University</a></li>
        <li><a rel="noreferrer" href="https://www.moreheadstate.edu/college-of-science/agricultural-sciences/academic-programs/equine-science" target="_blank">Morehead State University</a></li>
        <li><a rel="noreferrer" href="https://www.murraystate.edu/academics/CollegesDepartments/HutsonSchoolOfAgriculture/Programs/Undergraduateprograms.aspx" target="_blank">Murray State University</a></li>
        <li><a rel="noreferrer" href="https://www.asbury.edu/academics/departments/science-health/equine-program/" target="_blank">Asbury University</a></li>
        <li><a rel="noreferrer" href="https://bluegrass.kctcs.edu/education-training/program-finder/equine-studies.aspx" target="_blank">Bluegrass Community and Technical School (North American Racing Academy)</a></li>
        <li><a rel="noreferrer" href="https://khs.edu/splash/" target="_blank">Kentucky Horseshoeing School</a></li>
      </ul>

      <strong>Breed Associations & Organizations</strong>
      <ul>
        <li><a rel="noreferrer" href="https://www.asha.net/" target="_blank">American Saddlebred and Breeders Association</a></li>
        <li><a rel="noreferrer" href="https://www.hackneysociety.com/" target="_blank">American Hackney Horse Society</a></li>
        <li><a rel="noreferrer" href="https://www.morganhorse.com/" target="_blank">American Morgan Horse Association</a></li>
        <li><a rel="noreferrer" href="https://www.arabianhorses.org/" target="_blank">Arabian Horse Association</a></li>
        <li><a rel="noreferrer" href="https://www.usef.org/" target="_blank">US Equestrian</a></li>
        <li><a rel="noreferrer" href="https://www.usdf.org/" target="_blank">United States Dressage Federation</a></li>
        <li><a rel="noreferrer" href="https://www.ushja.org/" target="_blank">United States Hunter Jumper Association</a></li>
        <li><a rel="noreferrer" href="https://toba.org/" target="_blank">Thoroughbred Owners and Breeders Association</a></li>
        <li><a rel="noreferrer" href="https://hanoverian.org/" target="_blank">American Hanoverian Association</a></li>
        <li><a rel="noreferrer" href="https://www.caaonline.com/" target="_blank">Carriage Association of America</a></li>
        <li><a rel="noreferrer" href="https://fhana.com/" target="_blank">Friesian Horse Association of North America </a></li>
        <li><a rel="noreferrer" href="https://www.kmsha.com/" target="_blank">Kentucky Mountain Saddle Horse Association</a></li>
        <li><a rel="noreferrer" href="https://www.kentuckybred.org/" target="_blank">Kentucky Thoroughbred Owners Association/Kentucky Thoroughbred Owners & Breeders</a></li>
        <li><a rel="noreferrer" href="https://www.ponyclub.org/" target="_blank">US Pony Club</a></li>
        <li><a rel="noreferrer" href="https://www.aqha.com/" target="_blank">American Quarter Horse Association</a></li>
        <li><a rel="noreferrer" href="https://www.kyqha.com/" target="_blank">Kentucky Quarter Horse Association</a></li>
        <li><a rel="noreferrer" href="http://www.khja.org/" target="_blank">Kentucky Hunter Jumper Association</a></li>
        <li><a rel="noreferrer" href="https://www.kahaba.org/" target="_blank">Kentucky Arabian Horse Association</a></li>
        <li><a rel="noreferrer" href="http://www.southcentralhackney.com/" target="_blank">South Central Hackney Horse Association</a></li>
        <li><a rel="noreferrer" href="https://mpha-oldkentuckysaddler.com/" target="_blank">Mountain Pleasure Horse Association</a></li>
        <li><a rel="noreferrer" href="https://sites.google.com/site/kyappaloosas/" target="_blank">Kentucky Appaloosa Owners Association</a></li>
        <li><a rel="noreferrer" href="http://www.kentuckyminiaturehorsebreeders.org/" target="_blank">Kentucky Miniature Horse Breeders Club</a></li>
        <li><a rel="noreferrer" href="https://www.kyphc.org/" target="_blank">Kentucky Paint Horse Club</a></li>
        <li><a rel="noreferrer" href="http://www.khha.net/" target="_blank">Kentucky Harness Horsemen’s Association</a></li>
        <li><a rel="noreferrer" href="https://www.kypfha.org/" target="_blank">Kentucky Paso Fino Horse Association</a></li>
      </ul>

      <strong>Equine Education</strong>
      <ul>
        <li><a rel="noreferrer" href="https://afs.ca.uky.edu/4h-youth/horse/about" target="_blank">Kentucky 4-H Horse Program</a></li>
        <li><a rel="noreferrer" href="https://www.ffa.org/" target="_blank">Future Farmers of America</a></li>
        <li><a rel="noreferrer" href="https://www.khpfoundation.org/education/" target="_blank">Kentucky Horse Park Foundation</a></li>
        <li><a rel="noreferrer" href="https://www.derbymuseum.org/Education/" target="_blank">Kentucky Derby Museum</a></li>
        <li><a rel="noreferrer" href="https://equine.ca.uky.edu/saddleupsafely" target="_blank">Saddle Up Safely</a></li>
        <li><a rel="noreferrer" href="https://www.teachkyag.org/horsescience" target="_blank">Teach KY Ag</a></li>
      </ul>

      </main>
    </Layout>
  )
}

export default Resources

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`